@import "../../styles/colors.scss";
@import "../../styles/mixins.scss";

.header-box-shadow {
  box-shadow: 0 4px 6px 4px rgba(163, 163, 164, 0.1) !important;
}
.general-header-placeholder {
  height: 124px;
  width: 100%;
}
.landing-header-placeholder {
  width: 100%;
}

.header-wrapper {
  width: 100%;
  z-index: 100;
  box-sizing: border-box;
  border-radius: 0px !important;
  background-color: transparent !important;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  .store-status-ribbon {
    text-align: center;
    background-color: $warning;
  }
  .main-header {
    box-sizing: border-box;
    width: 100%;
    .header-container {
      padding: 8px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      gap: 8px;
      .left-section {
        width: 100%;
        display: flex;
        align-items: center;
        .header-cta-wrapper {
          .menu-cta,
          .back-cta {
            width: 32px;
            height: 32px;
            margin-inline-end: 12px;
            cursor: pointer;
            border-radius: 6px;
            @include align-center;
            margin-inline-end: 12px;
          }
          .menu-cta {
            display: none;
          }
        }
        .brand-name-wrapper {
          padding: 2.5px 0;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-inline-end: 12px;
          .brand-logo {
            width: 48px;
            height: 48px;
            cursor: pointer;
            object-fit: contain;
            border-radius: 6px;
          }
        }
      }
      .right-section {
        width: 100%;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        .sort-filter-search-wrapper {
          width: 100%;
          display: flex;
          gap: 8px;
          position: relative;
          flex-direction: row-reverse;
          .search-bar-wrapper {
            flex-grow: 1;
            border-radius: 6px;
            width: 100%;
            .search-bar {
              width: 100%;
              .MuiInputAdornment-positionStart {
                margin-bottom: 15px;
                // don't change this to margin-inline-end, margin-right makes sure the
                // search icon is in the center
                margin-right: 0px !important;
              }

              .MuiInputBase-root {
                border-radius: 6px;
                padding-left: 13px;
                background-color: rgba(247, 247, 247) !important;
                height: 40px;
                cursor: pointer;
                input {
                  padding: 15px;
                  font-size: 14px;
                  color: #182135;
                  &::placeholder {
                    color: #182135;
                  }
                }
              }
            }
          }
        }
        .add-to-cart-wrapper {
          display: none;
        }
      }
      .qr-code-store-address {
        .qr-code-store-welcome {
          display: flex;
          gap: 4px;
          .qr-code-store-address-text {
            text-transform: capitalize;
          }
        }
      }
      .qr-mode-table-info {
        align-self: flex-end;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        padding: 4px;
        gap: 4px;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .general-header-placeholder {
    min-height: 132px;
  }
  .header-wrapper {
    .main-header {
      margin-top: 0px !important;
      padding: 0px 16px;
      .header-container {
        padding: 12px;
        height: 100px;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .left-section {
          .header-cta-wrapper {
            .menu-cta,
            .back-cta {
              width: 42px;
              height: 42px;
            }
            .menu-cta {
              display: flex;
            }
          }
          .brand-name-wrapper {
            display: flex;
            justify-content: flex-start;
            margin-inline-end: 25px;
            border-bottom: none;
            padding-bottom: 0;
            .brand-logo {
              width: 120px;
              height: 80px;
              cursor: pointer;
              object-fit: contain;
              border-radius: none;
            }
          }
        }

        .right-section {
          gap: 5%;
          .sort-filter-search-wrapper {
            position: relative;
            gap: 10%;
            padding: 0 80px;
            .search-bar-wrapper {
              box-sizing: border-box;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              align-self: flex-end;
              flex-grow: 1;
              width: 40px;
              height: 40px;
              border-radius: 6px;
              .search-bar {
                width: 100%;
                .search-bar-icon,
                .cross-icon {
                  width: 16px;
                  height: 16px;
                  display: block;
                  cursor: pointer;
                }
              }
            }
          }

          .add-to-cart-wrapper {
            display: initial;
            position: relative;
          }
        }
      }
    }
  }

  .landing-header-placeholder {
    display: block;
  }
}

.user-session-popover {
  z-index: 10001;
}
