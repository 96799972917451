@import "../../styles/colors.scss";
@import "../../styles/mixins.scss";

.hide-popover {
  display: none;
}

.show-popover {
  display: initial;
}
