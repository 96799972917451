@import "../../styles/mixins.scss";
@import "../../styles/colors.scss";

.location-search-wrapper {
  .selected-location-wrapper {
    @include align-center;
    cursor: pointer;
    .deliver-heading-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 9rem;
    }
    .location-icon {
      width: 20px;
      height: auto;
      margin-inline-end: 8px;
      display: none;
    }

    .chevron-icon {
      width: 12px;
      height: auto;
      margin-inline-end: 5px;
      display: none;
    }
    .selected-location-text {
      max-width: 180px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-inline-end: 6px;
      @include noselect;
      &::first-letter {
        text-transform: uppercase;
      }
    }
    .location-value {
      margin-inline-end: 3px;
      opacity: 0.8;
    }
  }
}

.location-search-dialog-root {
  width: 100%;
  .location-search-dialog-container {
    width: 100%;
    align-items: flex-end !important;
    justify-content: flex-start;
    .location-search-dialog-paper {
      min-width: 100vw;
      width: 100%;
      margin: 0px;
      border-radius: 8px 8px 0 0;
    }
  }
}

@media only screen and (min-width: 768px) {
  .location-search-wrapper {
    padding: 2px 0px;
    .selected-location-wrapper {
      //   padding: 2px 0px;
      justify-content: flex-start;
      .deliver-heading-wrapper {
        display: none;
      }
      .location-icon {
        width: 20px;
        margin-inline-end: 12px;
        display: block;
      }
      .chevron-icon {
        width: 16px;
        margin-inline-start: 8px;
        display: block;
      }
      .selected-location-text {
        max-width: 180px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .location-value {
        margin-inline-end: unset;
        opacity: unset;
      }
    }
  }
  .location-search-dialog-root {
    width: auto !important;
    top: 110px !important;
    .MuiBackdrop-root {
      opacity: 0 !important;
      transform: none !important;
      transition: none !important;
    }
    .location-search-dialog-container {
      transform: none !important;
      transition: none !important;
      align-items: flex-start !important;
      .location-search-dialog-paper {
        width: 360px;
        min-width: 360px;
        height: auto;
        min-height: 0;
        margin: 0px;
        border-radius: 8px;
        box-shadow: 0 4px 6px 4px rgba(163, 163, 163, 0.16);
        .location-search-title-wrapper {
          display: none;
        }
        .search-results-wrapper {
          height: 400px;
        }
      }
    }
  }
}
