@import "../../styles/mixins.scss";

.badge-wrapper {
  width: 22px;
  height: 22px;
  top: -12px;
  right: -12px;
  position: absolute;
  border-radius: 50%;
  padding: 4px;
  @include align-center;
}
