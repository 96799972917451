@import "../../styles/colors.scss";
@import "../../styles/mixins.scss";

.invalid-qr-wrapper {
  box-sizing: border-box;
  height: 100vh;
  display: grid;
  place-items: center;
  padding: 16px;
  .invalid-qr-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 5px;
    max-width: 600px;
    width: 100%;
    .section-image {
      width: 100%;
      .qr-invalid-image {
        width: 100%;
      }
    }
  }
}
