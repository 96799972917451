@import "../../styles/colors.scss";

.filter-by-wrapper {
  cursor: pointer;
  .filter-by-button {
    display: flex;
    padding: 12px;
    position: relative;
    border-radius: 6px;
    align-items: center;
    background-color: $alabaster-color;
    .filter-name {
      white-space: nowrap;
      margin-right: 5px;
    }
    .filter-name,
    .chevron-icon {
      display: none;
    }
    .filter-applied-indicator {
      width: 10px;
      height: 10px;
      top: -4px;
      right: -4px;
      position: absolute;
      border-radius: 50%;
    }
  }
}
.filter-by-dialog-root {
  width: 100%;
  .filter-by-dialog-container {
    align-items: flex-end !important;
    .filter-by-dialog-paper {
      width: 100%;
      height: 70%;
      margin: 0px;
      border-radius: 8px 8px 0 0;
      .filter-by-title-wrapper {
        display: flex;
        padding: 16px 16px 0px 16px;
        align-items: center;
        justify-content: flex-end;
        .close-dialog-icon {
          width: 16px;
          height: 16px;
        }
      }
      .filter-by-option-container {
        flex: 1;
        overflow-y: auto;
        margin: 0px 0px 8px 0px;
        flex-wrap: nowrap !important;
        .filter-by-option-list {
          padding: 0px 16px 0px 10px;
          .filter-title {
            font-size: 12px;
            text-transform: capitalize;
          }
          .filter-by-container {
            margin: 2px 0px;
            .MuiButtonBase-root {
              width: 16px;
              height: 16px;
              img {
                width: 16px;
                height: 16px;
                margin-inline-end: 5px;
              }
            }
          }
        }
      }
      .filter-by-button-wrapper {
        display: flex;
        padding: 18px 12px;
        justify-content: space-between;
        border-top: 1px solid rgba(163, 163, 164, 0.16);
        .filter-action-button {
          height: 32px;
          margin: 0px 5px;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .filter-by-wrapper {
    .filter-by-button {
      margin-inline-start: 0px;
      background-color: transparent;
      .button-icon {
        display: none;
      }
      .filter-name,
      .chevron-icon {
        display: block;
      }
      .filter-applied-indicator {
        top: 5px;
        right: 5px;
      }
    }
  }
  .filter-by-dialog-root {
    width: auto !important;
    top: 110px !important;
    .MuiBackdrop-root {
      opacity: 0 !important;
      transform: none !important;
      transition: none !important;
    }
    .filter-by-dialog-container {
      transform: none !important;
      transition: none !important;
      align-items: flex-start !important;
      justify-content: flex-start;
      .filter-by-dialog-paper {
        width: 260px;
        height: 320px;
        margin: 0px;
        border-radius: 8px;
        box-shadow: 0 4px 6px 4px rgba(163, 163, 163, 0.16);
        .filter-by-title-wrapper {
          display: none;
          .close-dialog-icon {
            display: none;
          }
        }
        .filter-by-option-container {
          margin: 8px 0px 8px 0px;
          .filter-by-option-list {
            padding: 8px 16px 8px 10px;
            .filter-title {
              font-size: 14px;
            }
          }
        }
        .filter-by-button-wrapper {
          .filter-action-button {
            height: 28px;
          }
        }
      }
    }
  }
}
