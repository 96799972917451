@import "../../styles/mixins.scss";

@mixin cart-icon {
  width: 16px;
  height: 16px;
}

.view-cart-cta,
.cart-cta-wrapper {
  display: none;
}
.view-cart-text {
  white-space: nowrap;
}

@media only screen and (min-width: 768px) {
  .cart-cta-wrapper {
    @include align-center;
    width: 40px;
    height: 40px;
    border-radius: 6px;
    .cart-icon {
      @include cart-icon;
    }
  }
  .view-cart-cta {
    height: 40px;
    @include align-center;
    border-radius: 6px;
    padding: 16px;
    box-sizing: border-box;
    cursor: pointer;
    .cart-icon {
      @include cart-icon;
    }
    .view-cart-text {
      width: 60px;
      margin-inline-start: 10px;
    }
    .cart-sab-total {
      margin-inline-start: 10px;
    }
  }
}
