@import "../../styles/colors.scss";

.sub-header {
  display: none;
  position: relative;
  z-index: 2000;
  width: 100%;
  height: 24px;

  align-items: center;
  white-space: nowrap;
  padding: 0px 12px;
  align-items: center;
  box-sizing: border-box;
  @mixin sub-header-cta {
    letter-spacing: 1px;
    text-decoration: none;
    text-transform: uppercase;
  }
  .sub-header-container {
    display: flex;
    justify-content: space-between;
    .left-section {
      width: 45%;
      display: flex;
      align-items: center;
      .download-app-link {
        @include sub-header-cta;
        text-align: left;
        display: flex;
        align-items: center;
        svg {
          width: 20px;
          height: 20px;
          margin-inline-start: 5px;
        }
      }
    }
    .right-section {
      width: 55%;
      display: flex;
      justify-content: flex-end;
      > * {
        margin-inline-start: 6px;
      }
      .contact-us-link {
        @include sub-header-cta;
      }
      .language-selector {
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        cursor: pointer;
        .language-divider {
          margin: -3px 5px 0;
        }
      }
      .auth-wrapper {
        display: flex;
        align-items: center;
        > * {
          margin: 0 6px;
        }
        .login-link {
          @include sub-header-cta;
          cursor: pointer;
        }
        .signup-link,
        .user-name-link {
          @include sub-header-cta;
          cursor: pointer;
        }
      }
      .user-logged-in-state {
        display: flex;
        cursor: pointer;
        align-items: center;
        .chevron-down {
          width: 8px;
          height: 8px;
          margin-inline-start: 5px;
        }
      }
    }
  }
}
@media only screen and (min-width: 768px) {
  .sub-header {
    display: flex;
    padding: 0px 16px;
    .sub-header-container {
      .right-section {
        > * {
          margin: 16px;
        }
      }
    }
  }
  .user-logged-in-state {
    position: relative;
    z-index: 2000;
    .user-loggedin-state-popover {
      background-color: $white-color;
      position: absolute;
      top: 25px;
      width: 180px;
      border-radius: 8px;
      box-shadow: 0 4px 6px 4px rgba(163, 163, 164, 0.1) !important;
      .menu-link {
        padding: 12px 16px;
        cursor: pointer;
        color: $mine-shaft;
        border-bottom: 1px solid rgba(163, 163, 164, 0.16);
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .sub-header {
    height: 26px;
    .sub-header-container {
      .right-section {
        > * {
          margin-inline-start: 16px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1440px) {
  .sub-header {
    height: 30px;
    .sub-header-container {
      .right-section {
        > * {
          margin-inline-start: 24px;
        }
      }
    }
  }
}
